<template>
    <div class="next mail">
        <div class="title">
            <span> {{ $t('my.mail.mailTitle') }} </span>
            <div class="fixed" @click="goOtherPage('/mine')">
                <svg t="1658129167849" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    p-id="12456" width="0.35rem" height="0.35rem">
                    <path
                        d="M245.01248 555.52512l447.0784 447.0784c22.42048 22.42048 58.81856 22.42048 81.31584 0 22.42048-22.42048 22.42048-58.81856 0-81.31584l-406.49216-406.4256 406.49728-406.49216c22.42048-22.42048 22.42048-58.81856 0-81.31584-22.42048-22.42048-58.81856-22.42048-81.31584 0L244.9408 474.20928c-22.43584 22.47168-22.40512 58.88 0.07168 81.31584 0-0.00512 0 0 0 0z"
                        fill="#666666" p-id="12457"></path>
                </svg>
            </div>
            <div v-show="isShowEdit" @click="this.tabList[this.activeTab].isShowEditDetail = !this.tabList[this.activeTab].isShowEditDetail" class="title_ellipsis">
                {{ isShowEditDetail ? $t('common.finish') :  $t('common.edit')}}
            </div>
        </div>

        <div class="news">
            <van-tabs v-model:active="activeTab" @change="changeTab" :ellipsis="false" title-active-color="#EB457E" title-inactive-color="#666">
                <van-tab v-for="(item, index) in tabList" :key="`${index}--bar`" :title="`${item.title}`">
                    <template #title>
                        <van-badge :content="item.count" max="99" color="#E75D58" :show-zero="false">
                            <div>{{ item.title }}</div>
                        </van-badge>
                    </template>
                </van-tab>
            </van-tabs>
            
            <van-list
              v-model="loading"
              :finished="finished"
              style="overflow-y:scroll;overflow-x:hidden;"
              :style="{ height: isShowEditDetail && isShowEdit ? 'calc(100vh - 2.92rem)' : 'calc(100vh - 2.02rem)' }"
              :offset="10" 
              :immediate-check="check" 
              :finish-text="`${$t('lottery.noMore')}`"
              @load="onLoad" 
            >
                <div v-if="activeTab==0">
                    <div v-for="(item, index) in this.tabList[0].detailList" :key="`${index}--this.tabList[0].detailList-item`" @click="goNext(item, 1, index)" class="newsItem flexCenter">
                        <div class="userAvatarBox">
                            <img src="../../assets/img/my/mail/backstage.png">
                            <span v-show="item && item.status === 0"></span>
                        </div>
                        <div style="width: 100%;">
                            <div class="flexBetween">
                                <div class="newsItemTitle">【MMLive - {{ $t('my.mail.title') }}】</div>
                                <div class="time">{{ item.createTime ? getTime(item.createTime) : "--" }}</div>
                            </div>
                            <div class="newsItemContent" :style="{ width: `calc(100vw - 1.4rem)`}">
                                <p>{{ item.content ? item.content : "" }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="activeTab==1">
                    <div v-for="(item, index) in this.tabList[1].detailList" :key="`${index}--this.tabList[0].detailList-item`">
                        <van-swipe-cell :disabled="isShowEditDetail"  @open="handleOpen(index, 1)" @close="handleClose(index, 1)">
                            <div class="newsItem flexCenter" @click="goNext(item, 2, index)">
                                <div v-show="isShowEditDetail && isShowEdit">
                                    <img v-show="item.select" @click.stop="changeStstus(item, 1)"  class="newsItemIcon" src="../../assets/img/my/mail/select.png">
                                    <img v-show="!item.select" @click.stop="changeStstus(item, 1)"  class="newsItemIcon" src="../../assets/img/my/mail/noSelect.png">
                                </div>
                                <div class="userAvatarBox">
                                    <img src="../../assets/img/my/mail/automatic.png">
                                    <span class="userRead" v-if="item && item.status === 0"></span>
                                </div>
                                <div style="width: 100%;">
                                    <div class="flexBetween">
                                        <div class="newsItemTitle">{{ $t('my.mail.systemNotice') }}</div>
                                        <div class="time">{{ item.sendTime ? getTime(item.sendTime) : "--" }}</div>
                                    </div>
                                    <div class="newsItemContent" :style="{ width: isShowEditDetail ? `calc(100vw - 1.96rem)` : `calc(100vw - 1.4rem)`}">
                                        <p>{{ item.content ? item.content : "" }}</p>
                                    </div>
                                </div>
                            </div>
                            <template #right>
                                <van-button v-if="!item.status" class="itemEditbutton" square color="#3D83E6" :text="`${$t('my.mail.isRead')}`" @click.stop="changeSingleLetterStatus(index, 1)"/>
                                <van-button class="itemEditbutton" square color="#E75D58" :text="`${$t('my.mail.delete')}`" @click.stop="changeSingleLetterStatus(index, 2)"/>
                            </template>
                        </van-swipe-cell>
                    </div>
                </div>

                <div v-if="activeTab==2" >
                    <div v-for="(item, index) in this.tabList[2].detailList" :key="`${index}--this.tabList[2].detailList-item`" >
                        <van-swipe-cell :disabled="isShowEditDetail" @open="handleOpen(index, 2)" @close="handleClose(index, 2)">
                            <div class="newsItem flexCenter" @click="goDetail(item, index)">
                                <div v-show="isShowEditDetail && isShowEdit">
                                    <img v-show="item.select" @click.stop="changeStstus(item, 2)" class="newsItemIcon" src="../../assets/img/my/mail/select.png">
                                    <img v-show="!item.select" @click.stop="changeStstus(item, 2)"  class="newsItemIcon" src="../../assets/img/my/mail/noSelect.png">
                                </div>
                                <div class="userAvatarBox">
                                    <img v-real-img="item.avatar" :errorimg="require(`../../assets/img/errorImg/3.png`)"/>
                                </div>
                                <div style="width: 100%;">
                                    <div class="flexBetween">
                                        <van-badge :content="item.otherUnreadNum" max="99" color="#E75D58" :show-zero="false">
                                            <div class="newsItemTitle">{{item.nickname }}</div>
                                        </van-badge>
                                        <div class="time">{{ item.sendTime ? getTime(item.sendTime) : "--" }}</div>
                                    </div>
                                    <div class="newsItemContent" :style="{ width: isShowEditDetail ? `calc(100vw - 1.96rem)` : `calc(100vw - 1.4rem)`}">
                                        <p>{{ item.content }}</p>
                                    </div>
                                </div>
                            </div>
                            <template #right>
                                <van-button v-if="!item.status" class="itemEditbutton" square color="#3D83E6" :text="`${$t('my.mail.isRead')}`" @click.stop="changeSingleLetterStatus(index, 1)"/>
                                <van-button class="itemEditbutton" square color="#E75D58" :text="`${$t('my.mail.delete')}`" @click.stop="changeSingleLetterStatus(index, 2)"/>
                            </template>
                        </van-swipe-cell>
                    </div>
                </div>
            </van-list>
        </div>

        <div v-show="isShowEditDetail && isShowEdit" class="footerEditBox flexBetween">
            <div @click="changeAllSelectStatus" class="flexCenter">
                <img v-show="isAllSelect" class="newsItemIcon" src="../../assets/img/my/mail/select.png" />
                <img v-show="!isAllSelect" class="newsItemIcon" src="../../assets/img/my/mail/noSelect.png" />
                <span>{{ $t("my.mail.selectAll") }}</span>
            </div>
            <div @click="changeLetterStatus(1)" class="flexCenter">
                <img class="newsItemIcon" src="../../assets/img/my/mail/read.png" />
                <span>{{ $t("my.mail.isRead") }}</span>
            </div>
            <div @click="changeLetterStatus(2)" class="flexCenter">
                <img class="newsItemIcon" src="../../assets/img/my/mail/delete.png" />
                <span>{{ $t("my.mail.delete") }}</span>
            </div>
        </div>
        <mailDetail v-if="showMailDetail" @closeMailDetail="closeMailDetail" @changeSingleLetterStatus="changeSingleLetterStatus" @changeStstus="changeStstus"></mailDetail>
        <privateLetter v-if="showPrivateLetter" @updatePrivater="updatePrivater" @updatePrivateLetter="updatePrivateLetter" @closePrivateLetter="closePrivateLetter"></privateLetter>
        
    </div>
</template>

<script>
import { getSystemLetter, getUnreadNum, getUserLetter, getNotice, changeNoticeRead, changeLetterStatusById, changeLetterStatusByOtherUid } from "@/api/index";
import moment from 'moment';
import { mapState } from 'vuex';
import privateLetter from "@/pages/components/privateLetter.vue";
import mailDetail from "@/pages/my/mailDetail.vue";
import utils from "@/utils/index";
import md5 from 'js-md5';
export default {
    components: {
        privateLetter,
        mailDetail
    },
    data() {
        return {
            showPrivateLetter: false,
            isGetData: false,
            tabList: [
                {
                    title: `${this.$t('my.mail.title')}`,
                    tab: 0,
                    detailList: [],
                    count: 0,
                    finished: true,
                    scrollTop: 0,
                    isShowEditDetail: false
                },
                {
                    title: this.$t('my.mail.systemNotice'),
                    tab: 1,
                    detailList: [],
                    count: 0,
                    finished: false,
                    loading: false,
                    scrollTop: 0,
                    isShowEditDetail: false
                },
                {
                    title: this.$t('my.privateLetter'),
                    tab: 2,
                    detailList: [],
                    count: 0,
                    finished: false,
                    loading: false,
                    scrollTop: 0,
                    isShowEditDetail: false
                }
            ],
            activeTab: 0,
            loading: false, //下拉加载更多加载中动画
            check: false, //是否开始就检查
            finished: false, //是否已加载完
            // isShowEditDetail: false,
            showMailDetail: false,
            isAllSelect: false
        }
    },
    computed: {
        ...mapState(['member']),
        isShowEdit() {
            let flag = false;
            if (this.activeTab && this.tabList[this.activeTab].detailList.length > 1) {
                flag = true;
            }
            return flag
        },
        isShowEditDetail() {
            return this.tabList[this.activeTab].isShowEditDetail;
        }
    },
    watch: {
        activeTab(val, old) {
            let box = document.querySelector('.van-list');
            if (box) {
                this.tabList[old].scrollTop = box.scrollTop;
                setTimeout(() => {
                    box.scrollTop = this.tabList[val].scrollTop;
                }, 10)
            }
        }
    },
    created() {
        this.getUnreadNum();
        this.systemNotice(); //MMlive 通知
        this.systemLetterList(); //系统通知
        this.getLetterList(); //私信
        this.$store.commit("setState", { betPramas: {} });
    },
    methods: {
        changeTab(val) {
            this.finished = this.tabList[val].finished;
            this.computeIsSelectAll();
        },
        computeIsSelectAll() {
            if (!this.activeTab) return;
            let flag = true;
            this.tabList[this.activeTab].detailList.forEach(item => {
                if (!item.select) {
                    flag = false;
                }
            });
            
            this.isAllSelect = flag;
        },
        changeAllSelectStatus() {
            this.tabList[this.activeTab].detailList.forEach(item => {
                item.select = !this.isAllSelect;
            });
            this.computeIsSelectAll();
        },
        changeStstus(item, type) {
            let fields = type == 1 ? 'letterId' : 'uid',
            index = this.tabList[type].detailList.findIndex(xitem => {
                return xitem[fields] == item[fields]
            });

            if (index != -1) {
                this.tabList[type].detailList[index].select = !item.select;
            }

            setTimeout(()=> {
                this.computeIsSelectAll();
            }, 10)
        },
        onLoad() {
            if (!this.activeTab && !this.finished) {
               this.systemNotice();
            }

            if (this.activeTab == 1 && !this.finished) {
                this.systemLetterList();
            }
            
            if (this.activeTab == 2 && !this.finished) {
                this.getLetterList();
            }
        },
        getTime(time) {
            if (time) {
                return moment(time).format('HH:mm:ss DD-MM-YYYY');
            } 
            return "--";
        },
        // 关闭私信弹框
        closePrivateLetter() {
            this.showPrivateLetter = false;
        },
        closeMailDetail() {
            this.showMailDetail = false;
        },
        goOtherPage(path) {
            this.$router.push(path);
        },
        getUnreadNum() {
            getUnreadNum().then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.tabList[0].count = res.data.noticeUnreadNum;
                    this.tabList[1].count = res.data.systemLetterUnreadNum;
                    this.tabList[2].count = res.data.userLetterUnreadNum;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => { })
        },
        // 前往私信
        goDetail(item, index) {
            if (this.isShowEditDetail || item.eventFlag) return;
            let obj = JSON.parse(JSON.stringify(item));
            obj.mailIndex = index;
            this.$store.commit("setState", { betPramas: obj });
            this.showPrivateLetter = true;
            this.changeSingleLetterStatus(index, 1, true);
        },
        updatePrivateLetter(index, obj) {
            this.tabList[this.activeTab].detailList[index].content = obj.content;
            this.tabList[this.activeTab].detailList[index].sendTime = obj.sendTime;
        },
        handleOpen(index, Findex) {
            this.tabList[Findex].detailList[index].eventFlag = true;
        },
        handleClose(index, Findex) {
            this.tabList[Findex].detailList[index].eventFlag = false;
        },
        // 系统公告
        systemNotice() {
            if(this.tabList[0].loading) return;
            let param = {
                language: "th"
            }, detailList = this.tabList[0].detailList;
            if(detailList.length > 0) param.sendTime = detailList[detailList.length - 1].createTime
            this.tabList[0].loading = true;
            getNotice(param).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.tabList[0].detailList = [...this.tabList[0].detailList, ...res.data];
                    if (res.data.length < 20) this.tabList[0].finished = true;
                    if (this.activeTab == 0 && res.data.length < 20) this.finished = true;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                this.tabList[0].loading = false;
            }).catch(() => { 
                this.tabList[0].loading = false;
            })
        },
        // 系统通知 
        systemLetterList() {
            if(this.tabList[1].loading) return;
            let param = {
                language: "th"
            }, detailList = this.tabList[1].detailList;
            if(detailList.length > 0) param.sendTime = detailList[detailList.length - 1].sendTime;
            this.tabList[1].loading = true;
            getSystemLetter(param).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.tabList[1].detailList = [...this.tabList[1].detailList, ...res.data];
                    if (res.data.length < 20) this.tabList[1].finished = true;
                    if (this.activeTab == 1 && res.data.length < 20) this.finished = true;
                    if(this.activeTab == 1) this.computeIsSelectAll();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                this.tabList[1].loading = false;
            }).catch(() => { 
                this.tabList[1].loading = false;
            })
        },
        // 私信
        getLetterList() {
            if(this.tabList[2].loading) return;
            let param = {
                language: "th",
            }, detailList = this.tabList[2].detailList;
            if(detailList.length > 0) param.sendTime = detailList[detailList.length - 1].sendTime
            this.tabList[2].loading = true;
            getUserLetter(param).then(res => {
                if (res.code === 0 || res.code === '0') {
                    this.tabList[2].detailList = [...this.tabList[2].detailList, ...res.data];
                    if (res.data.length < 20) this.tabList[2].finished = true;
                    if (this.activeTab == 2 && res.data.length < 20) this.finished = true;
                    if(this.activeTab == 2) this.computeIsSelectAll();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                this.tabList[2].loading = false;
            }).catch(() => {
                this.tabList[2].loading = false;
            });
        },
        goNext(item, type, index) {
            if (item.eventFlag) return;
            if (this.activeTab && this.isShowEdit && this.isShowEditDetail) return;
            let obj = JSON.parse(JSON.stringify(item));
            obj.titleType = type;
            obj.mailIndex = index;
            if (!obj.sendTime) obj.sendTime = obj.createTime;
            this.$store.commit("setState", { betPramas: obj });
            this.showMailDetail = true;

            if (!item.status) {
                if (!this.activeTab) {
                    this.changeNoticeRead(item, type, index);
                } else {
                    this.changeSingleLetterStatus(index, 1, true)
                }
            }
        },
        changeNoticeRead(item, type, index) {
            let uid = localStorage.getItem('uid') || utils.guid(),
            timestamp = new Date().getTime(),
            params = {
                domain: "",
                language: "th",
                noticeId: item.noticeId,
                os: 0,
                sign: md5(`${uid}jgyh,kasd${timestamp}`),
                timestamp: timestamp,
                udid: uid
            }
            changeNoticeRead(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    let obj = JSON.parse(JSON.stringify(item));
                    obj.status = 1;
                    this.tabList[type-1].detailList[index] = obj;
                    this.tabList[type-1].count = this.tabList[type-1].count - 1;
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {})
        },
        changeSingleLetterStatus(index, status, flag) {
            let uid = localStorage.getItem('uid') || utils.guid(),
            timestamp = new Date().getTime(),
            fun = null,
            letterIdList = [],
            otherUidList = [];
            if(this.activeTab == 1) letterIdList.push(this.tabList[this.activeTab].detailList[index].letterId);
            if(this.activeTab == 2) otherUidList.push(this.tabList[this.activeTab].detailList[index].uid);
            let params = {
                domain: "",
                language: "th",
                letterIdList,
                otherUidList,
                os: 0,
                sign: md5(`${uid}jgyh,kasd${timestamp}`),
                timestamp: timestamp,
                udid: uid,
                status: status
            }
            fun = this.activeTab == 1 ? changeLetterStatusById : changeLetterStatusByOtherUid;
            if(!flag) this.$store.commit("setState", { isLoadForLoad: true });
            fun(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    if (status == 1) {
                        let obj = JSON.parse(JSON.stringify(this.tabList[this.activeTab].detailList[index]));
                        obj.status = 1;
                        if(this.activeTab == 2) obj.otherUnreadNum = 0;
                        this.tabList[this.activeTab].detailList[index] = obj;
                    } else {
                        this.tabList[this.activeTab].detailList.splice(index, 1);
                        if (this.tabList[this.activeTab].detailList.length == 1) {
                            this.tabList[this.activeTab].detailList[0].select = false;
                        }
                    }
                    if(!flag) this.$toast(this.$t("my.mail.mailEidtSucess"));
                    this.getUnreadNum();
                    if(!flag) this.closePrivateLetter(), this.closeMailDetail();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
                this.$store.commit("setState", { isLoadForLoad: false });
            }).catch(() => { 
                this.$store.commit("setState", { isLoadForLoad: false });
            })
        },
        updatePrivater(index) {
            this.tabList[this.activeTab].detailList.splice(index, 1);
        },
        changeLetterStatus(status) {
            let uid = localStorage.getItem('uid') || utils.guid(),
            timestamp = new Date().getTime(),
            fun = null,
            letterIdList = [],
            otherUidList = [],
            newDetailList = [];
            this.tabList[this.activeTab].detailList.forEach(item => {
                if (item.select) {
                    if(this.activeTab == 1) letterIdList.push(item.letterId);
                    if(this.activeTab == 2) otherUidList.push(item.uid);
                } else {
                    newDetailList.push(item);
                }
            })
            if (otherUidList.length == 0 && letterIdList.length == 0) return this.$toast(this.$t('my.mail.minSelectTip'));
            let params = {
                domain: "",
                language: "th",
                letterIdList,
                otherUidList,
                os: 0,
                sign: md5(`${uid}jgyh,kasd${timestamp}`),
                timestamp: timestamp,
                udid: uid,
                status: status
            }
            this.$store.commit("setState", { isLoadForLoad: true });
            fun = this.activeTab == 1 ? changeLetterStatusById : changeLetterStatusByOtherUid;
            fun(params).then(res => {
                if (res.code === 0 || res.code === '0') {
                    if (status == 1) {
                        this.tabList[this.activeTab].detailList.forEach(item => {
                            if (item.select) {
                                item.status = 1;
                                item.select = false;
                                if(this.activeTab == 2) item.otherUnreadNum = 0;
                            }
                        })
                    } else {
                        if (newDetailList.length == 1) {
                            newDetailList[0].select = false;
                        }
                        let old = JSON.parse(JSON.stringify(this.tabList[this.activeTab].detailList));
                        if (old.length >= 20 && newDetailList.length < 20 && this.activeTab) {
                            this.onLoad();
                        }
                        this.tabList[this.activeTab].detailList = JSON.parse(JSON.stringify(newDetailList));
                    }
                    this.$toast(this.$t("my.mail.mailEidtSucess"));
                    this.getUnreadNum();
                    this.$store.commit("setState", { isLoadForLoad: false });
                    this.computeIsSelectAll();
                } else {
                    if (res.msg) this.$toast(res.msg);
                }
            }).catch(() => {
                this.$store.commit("setState", { isLoadForLoad: false });
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.mail {
    background-color: #fff;

    &.next {
        padding-top: 0.88rem;

        .title {
            box-shadow: 0 0.01rem 0.08rem 0 rgba(0, 0, 0, 0.05);
        }
    }

    .news {
        .newsItem {
            background-color: #fff;
            margin: 0.2rem auto 0;
            width: 100vw;
            border-radius: 0.2rem;
            padding-left: 0.2rem;

            .newsItemIcon {
                width: 0.36rem;
                margin-right: 0.24rem;
            }

            .userAvatarBox {
                width: 1.02rem;
                height: 1.02rem;
                margin-right: 0.2rem;
                position: relative;

                img {
                    width: 1.02rem;
                    height: 1.02rem;
                    border-radius: 50%;
                    object-fit: cover;
                }

                span {
                    display: inline-block;
                    width: 0.15rem;
                    height: 0.15rem;
                    background-color: #C41F1A;
                    position: absolute;
                    top: 0.1rem;
                    right: 0.1rem;
                    border-radius: 50%;
                }                
            }

            .newsItemTitle {
                font-size: 0.28rem;
                padding-right: 0.1rem;
            }

            .newsItemContent {
                text-align: left;
                width: 2.6rem;
                font-size: 0.24rem;
                color: #333;
                margin-top: 0.3rem;
                padding-bottom: 0.3rem;
                border-bottom: 1px solid #E7E7E7;

                p {
                    width: 100%;
                    overflow: hidden;
                    // text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .time {
                font-size: 0.24rem;
                color: #969696;
                padding-right: 0.2rem;
            }
        }
    }

    .footerEditBox {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 0.24rem;
        width: 100%;
        font-size: 0.26rem;
        color: #333;
        background-color: #fff;

        img {
            width: 0.36rem;
            margin-right: 0.12rem;
        }

        div:nth-child(1) {
            color: #FBA64E;
        }

        div:nth-child(3) {
            color: #E75D58;
        }
    }
}
</style>
<style lang="scss">
.mail {
    .van-tabs .van-tabs__line {
        background-color: #EB457E;
        min-width: 1.56rem !important;
    }
    .itemEditbutton {
        height: 100%;
    }
}
</style>
